import * as Styled from "./OxFindUsMap.styled";

import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import React, { useRef, useState } from "react";

import { OxFindUsMapMarker } from "./OxFindUsMapMarker";
import { geoMillerRaw } from "d3-geo-projection";
import { geoProjection } from "d3-geo";
import useScrollPosition from "@react-hook/window-scroll";
import { useTheme } from "styled-components";

export type TOxFindUsMapMarker = {
  offsetX?: number;
  offsetY?: number;
  name: string;
  isClickable?: boolean;
  key: string;
  coordinates: number[];
};

type TProps = {
  selectedMarker: TOxFindUsMapMarker;
  markers: Array<TOxFindUsMapMarker>;
  onChange: (marker: TOxFindUsMapMarker) => void;
};

const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

export const OxFindUsMap = (props: TProps): JSX.Element => {
  const [showMarkers, setShowMarkers] = useState(false);
  const mapRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const projection = geoProjection(geoMillerRaw)
    .scale(120)
    .center([38, 30])
    .rotate([-10, 0]);

  const onMarkerClick = (marker: TOxFindUsMapMarker): void => {
    props.onChange(marker);
  };

  const scrollY = useScrollPosition(30);
  if (!showMarkers && mapRef.current) {
    const detector = scrollY + window.innerHeight * 0.5;
    if (detector > mapRef.current.offsetTop) {
      setShowMarkers(true);
    }
  }

  return (
    <Styled.Container ref={mapRef} showMarkers={showMarkers}>
      <ComposableMap height={500} projection={projection}>
        <Geographies geography={geoUrl}>
          {({ geographies }): JSX.Element[] =>
            geographies
              .filter(geo => geo.properties.CONTINENT !== "Antarctica")
              .map(geo => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill="#ECE2D6"
                  stroke="#ECE2D6"
                />
              ))
          }
        </Geographies>
        {props.markers.map(marker => {
          const isActive = props.selectedMarker.key === marker.key;
          const fill = isActive ? theme.colors.primary.main : "currentColor";
          return (
            <OxFindUsMapMarker
              key={marker.key}
              fill={fill}
              marker={marker}
              onMarkerClick={onMarkerClick}
            />
          );
        })}
      </ComposableMap>
    </Styled.Container>
  );
};
