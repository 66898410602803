import React from "react";
import { OxStory } from "src/components/OxStory";
import { graphql, useStaticQuery } from "gatsby";

export const WhyInjectables = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      consultationTabletImage: file(
        relativePath: { eq: "homepage/BookingBanner-Tablet.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      consultationMobileImage: file(
        relativePath: { eq: "homepage/BookingBanner-Mobile.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);
  const storyImages = [
    {
      ...data.consultationMobileImage.childImageSharp.gatsbyImageData,
      media: `(max-width: 768px)`,
    },
    {
      ...data.consultationTabletImage.childImageSharp.gatsbyImageData,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <OxStory
      fluidImagesArray={storyImages}
      label="WHY INJECTABLES"
      title="Facial aesthetics vs plastic surgery"
      description="Unlike plastic surgery, facial aesthetics offers non-invasive, non-surgical treatments that can yield powerfully transformative results. Subtle in every way, our approach to facial aesthetics is more holistic; a philosophy that aims to harmonise the features rather than remodel them. Rejecting the idea of isolated ‘tweakments’ just as much as invasive surgery, our treatments are low-risk, low on recovery time but big on results that honour your existing, unique beauty. A more modern solution, our treatments work for who you are, optimising a result that’s beautifully you: alive with life and animated expression."
    />
  );
};
